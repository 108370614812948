import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"; // Ensure you're importing BrowserRouter
import { Modal, Button } from "react-bootstrap";
import "./App.css";
import ProductPage from "./Components/ProductPage";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Home from "./Components/Home";
import OurPolicy from "./Components/OurPolicy";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const handleWhatsAppChat = () => {
    const phoneNumber = "+918857043246";
    const defaultMessage = "Hello TradoXport, I'm interested in your spices...";
    const encodedMessage = encodeURIComponent(defaultMessage);
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodedMessage}`,
      "_blank"
    );
  };

  return (
    <BrowserRouter>
      {" "}
      {/* Make sure App is wrapped inside BrowserRouter */}
      <div className={`App ${darkMode ? "dark-mode" : ""}`}>
        <Navbar bg={darkMode ? "dark" : "primary"} expand="lg">
          <Container fluid>
            <Navbar.Brand className="navbar-brand">TradoXport</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/" className="nav-link white-text">
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/products"
                  className="nav-link white-text"
                >
                  Products
                </Nav.Link>
                <Nav.Link as={Link} to="/about" className="nav-link white-text">
                  About US
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contact"
                  className="nav-link white-text"
                >
                  Contact US
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/our-policy"
                  className="nav-link white-text"
                >
                  Our Policy
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link
                  onClick={toggleDarkMode}
                  className="nav-link white-text"
                >
                  {darkMode ? "Light Mode" : "Dark Mode"}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container fluid>
          <Routes>
            <Route path="/products" element={<ProductPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/our-policy" element={<OurPolicy />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Container>

        <Button
          variant="primary"
          onClick={handleWhatsAppChat}
          className="whatsapp-button"
        >
          Send us a message on WhatsApp
        </Button>

        <Modal show={showModal} onHide={handleToggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Modal Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>Modal Content Here...</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleToggleModal}>
              Close
            </Button>
            {/* Additional action buttons can go here */}
          </Modal.Footer>
        </Modal>
      </div>
    </BrowserRouter>
  );
}

export default App;
