import React, { useState } from "react";
import { Container, Card, Col, Row, Button, Collapse } from "react-bootstrap";

function OurPolicy() {
  const [open, setOpen] = useState(false);

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h2>Our Policy</h2>
          <Card>
            <Card.Header>
              <Button
                variant="link"
                onClick={() => setOpen(!open)}
                aria-controls="policy-content"
                aria-expanded={open}
              >
                {open
                  ? "Collapse Global Privacy Policy"
                  : "Read Our Global Privacy Policy"}
              </Button>
            </Card.Header>
            <Collapse in={open}>
              <div id="policy-content">
                <Card.Body className="text-left">
                  <p className="mb-1">Last updated: 01-April-2024</p>
                  <p className="mb-1">
                    This page informs you of our policies regarding the
                    collection, use, and disclosure of personal data when you
                    use our Service and the choices you have associated with
                    that data.
                  </p>
                  <p className="mb-1">
                    We use your data to provide and improve the Service. By
                    using the Service, you agree to the collection and use of
                    information in accordance with this policy.
                  </p>
                  <h4>Information Collection and Use</h4>
                  <p className="mb-1">
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                  </p>
                  <h5>Types of Data Collected</h5>
                  <p className="mb-1">
                    <strong>Personal Data</strong>
                  </p>
                  <p className="mb-1">
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you ("Personal Data"). Personally
                    identifiable information may include, but is not limited to:
                  </p>
                  <ul
                    className="mb-1"
                    style={{ listStyleType: "disc", marginLeft: "1rem" }}
                  >
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, State, Province, ZIP/Postal code, City</li>
                    <li>Cookies and Usage Data</li>
                  </ul>
                  <p className="mb-1">
                    We may use your Personal Data to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or instructions provided in
                    any email we send.
                  </p>
                </Card.Body>
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default OurPolicy;
