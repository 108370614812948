// Contact.jsx
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import GetInTouch from "./GetInTouch";

function Contact() {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h5>
            <p>
              Thank you for your interest in TradoXport. We're here to assist
              you in any way we can. Please feel free to reach out to us using
              any of the following methods:
            </p>
          </h5>
          <h3 style={{ textAlign: "left" }}>Contact Information:</h3>
          <p style={{ fontWeight: "bold" }}>
            Email:{" "}
            <a href="mailto:tradoxport@gmail.com">tradoxport@gmail.com</a>
          </p>
          <p style={{ fontWeight: "bold" }}>
            Phone: <a href="tel:+918857043246">+91 8857043246</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
