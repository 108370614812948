import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function ProductPage() {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h1>Our Products</h1>
          <p className="text-left">
            At TradoXport, we take pride in offering a diverse range of
            high-quality spices. Here are some of our featured products:
          </p>
          <h4 className="text-start">Black Pepper</h4>
          <p className="text-left">
            Known as the "King of Spices," black pepper is one of the most
            important spices exported from India.
          </p>
          <h4 className="text-start">Cardamom</h4>
          <p className="text-left">
            Often referred to as the "Queen of Spices," Indian cardamom is
            highly valued for its aroma and flavor.
          </p>
          <h4 className="text-start">Turmeric</h4>
          <p className="text-left">
            This bright yellow spice, known for its medicinal properties, is in
            high demand globally.
          </p>
          <h4 className="text-start">Cumin</h4>
          <p className="text-left">
            Cumin seeds are widely used in culinary practices across the world.
          </p>
          <h4 className="text-start">Coriander</h4>
          <p className="text-left">
            Both the seeds and the ground form of coriander are exported.
          </p>
          <h4 className="text-start">Ginger</h4>
          <p className="text-left">
            Fresh and dried ginger from India is highly sought after for its
            quality.
          </p>
          <h4 className="text-start">Chili</h4>
          <p className="text-left">
            India is one of the largest producers and exporters of various types
            of chilies.
          </p>
          <h4 className="text-start">Fenugreek</h4>
          <p className="text-left">
            Known for its slightly bitter taste, fenugreek seeds are exported
            for both culinary and medicinal uses.
          </p>
          <h4 className="text-start">Fennel</h4>
          <p className="text-left">
            Fennel seeds are popular in many global cuisines.
          </p>
          <h4 className="text-start">Mustard</h4>
          <p className="text-left">
            Mustard seeds are exported for use in cooking and for oil
            extraction.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductPage;
