import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function About() {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h1>
            Welcome to TradoXport, your trusted partner in the world of spices.
          </h1>
          <h2>Our Story</h2>
          <p>
            TradoXport was founded with a simple yet ambitious vision: to bring
            the vibrant flavours of the world's finest spices to kitchens around
            the globe. What started as a humble endeavour has grown into a
            thriving business, driven by our unwavering commitment to quality,
            integrity, and customer satisfaction.
          </p>
          <h2>Our Mission</h2>
          <p>
            At TradoXport, our mission is clear: to source and export the
            highest quality spices while fostering sustainable and ethical
            practices throughout our supply chain. We believe that every spice
            tells a story, and we are dedicated to sharing these stories with
            our customers while supporting the communities and farmers who
            cultivate these precious ingredients.
          </p>
          <h2>Our Values</h2>
          <Row>
            <Col xs={12} md={6}>
              <h3>Quality</h3>
              <p>
                We believe that quality is non-negotiable. That's why we go to
                great lengths to ensure that only the freshest and most
                flavorful spices make it from our suppliers to your doorstep.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <h3>Integrity</h3>
              <p>
                We conduct our business with the utmost honesty, transparency,
                and respect for all stakeholders, from our suppliers and
                employees to our customers and the environment.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <h3>Innovation</h3>
              <p>
                In an ever-evolving industry, we embrace innovation and
                continuously seek new ways to improve our products, processes,
                and customer experiences.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <h3>Sustainability</h3>
              <p>
                We are committed to promoting sustainable practices throughout
                our supply chain, from responsible sourcing and production to
                eco-friendly packaging and shipping methods.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Customer Satisfaction</h3>
              <p>
                Our customers are at the heart of everything we do. We strive to
                exceed their expectations by providing exceptional products,
                personalised service, and timely support.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
