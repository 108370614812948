// Home.jsx
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Home() {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h1>Welcome to TradoXport - Your Trusted Spice Exporter.</h1>
          <p className="text-bold text-lg">
            At TradoXport, we specialise in exporting premium quality spices
            sourced from the finest regions around the world. With a commitment
            to excellence and a passion for flavour, we strive to deliver the
            highest quality spices to our customers worldwide.
          </p>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col>
          <h2>Why Choose Us?</h2>
          <div>
            <p>
              <strong>Exceptional Quality:</strong> We take pride in our
              meticulous selection process, ensuring that only the freshest and
              most flavorful spices reach our esteemed importers.
            </p>
            <p>
              <strong>Wide Variety:</strong> From aromatic herbs to exotic
              peppers, we present a diverse array of spices to meet every
              culinary requirement and flavour preference of our esteemed
              importers.
            </p>
            <p>
              <strong>Global Sourcing:</strong> Leveraging our extensive network
              of trusted suppliers, we procure spices from prime regions
              worldwide, guaranteeing authenticity and freshness for our
              esteemed importers.
            </p>
            <p>
              <strong>Customised Solutions:</strong> Whether you operate a
              small-scale restaurant or a large food distributor, we provide
              adaptable solutions tailored precisely to your specific
              importation needs.
            </p>
            <p>
              <strong>Reliable Service:</strong> With our extensive industry
              experience, we are dedicated to delivering punctual and dependable
              service, ensuring that import orders are promptly fulfilled and
              delivered, consistently meeting the expectations of our valued
              importers.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
